import { RouteRecordRaw } from 'vue-router'
import BrowsePage from 'src/pages/Browse.vue'
import SearchPage from 'src/pages/SearchPage.vue'
import HomePage from 'src/pages/Home.vue'
import { useAppServer } from 'src/boot/AppServer'
import { useCartStore } from 'src/stores/cart'
import { useSiteInfoStore } from 'src/stores/siteInfo'

declare module 'vue-router' {
  interface RouteMeta {
    requiresLogin?: boolean
    sendToLoginPage?: boolean
    requiresCart?: boolean
    checkoutComplete?: boolean
    displayName?: string
  }
}

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'root',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'default',
        component: HomePage,
        alias: '/home',
      },
      {
        name: 'ProductDetails',
        path: '/product/:itemId',
        component: () => import('pages/ProductDetails.vue'),
      },
      {
        name: 'Format',
        path: '/format/:formatName',
        component: BrowsePage,
        children: [
          {
            name: 'BrowseFormat',
            path: '/format/:formatName/:collectionId+',
            component: BrowsePage,
          },
        ],
      },
      {
        name: 'Browse',
        path: '/browse',
        component: BrowsePage,
        children: [
          {
            name: 'BrowseCollection',
            path: '/browse/:collectionId+',
            component: BrowsePage,
          },
        ],
      },
      {
        name: 'Onboard',
        path: '/onboard',
        component: () => import('pages/UserOnboarding.vue'),
      },
      {
        name: 'Search',
        path: '/search',
        component: SearchPage,
      },
      {
        name: 'Account',
        path: '/account',
        component: () => import('pages/AccountMain.vue'),
        meta: { requireLogin: true },
        children: [
          {
            path: '',
            name: 'AccountHome',
            component: () => import('pages/AccountHome.vue'),
            meta: { requireLogin: true },
          },
          {
            name: 'Settings',
            path: 'settings',
            component: () => import('pages/AccountProfile.vue'),
            meta: { requireLogin: true, displayName: 'Settings' },
          },
          {
            name: 'OrderHistory',
            path: 'orders',
            component: () => import('pages/AccountOrders.vue'),
            meta: { requireLogin: true, displayName: 'Order History' },
          },
          {
            name: 'OrderDetails',
            path: 'orders/:orderIdentifier',
            component: () => import('pages/OrderDetailsPage.vue'),
            meta: { requiresLogin: true, backToOrders: true, displayName: 'Order Details' },
          },
          {
            name: 'AddressBook',
            path: 'addresses',
            component: () => import('pages/AccountAddressBook.vue'),
            meta: { requireLogin: true, displayName: 'Address Book' },
          },
        ],
      },
      {
        name: 'Cart',
        path: '/cart/:refereshToken*',
        component: () => import('pages/CartPage.vue'),
      },
      {
        name: 'PreCheckout',
        path: '/precheckout',
        component: () => import('pages/PreCheckout.vue'),
      },
      {
        name: 'Checkout',
        path: '/checkout',
        component: () => import('pages/CheckoutPage.vue'),
        meta: { requireLogin: true, sendToLoginPage: true, requireCart: true },
        // handle checkout navigations
        beforeEnter: async () => {
          const user = useSiteInfoStore().user
          if (user) {
            // if (user && !user.emailVerified) {
            // user must verify email before checking out
            // return { name: 'Checkout' }
          }
          let checkout = useCartStore().cart.checkout
          if (checkout === undefined) {
            // user must be manually trying to load a page and skip checkout init
            await useCartStore().fetchCart()
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            checkout = useCartStore().cart.checkout!
          }
        },
        children: [
          {
            name: 'CheckoutReview',
            path: '',
            component: () => import('components/checkout/CheckoutReview.vue'),
            meta: { requireLogin: true, sendToLoginPage: true, requireCart: true },
            beforeEnter: async () => {
              const checkout = useCartStore().cart.checkout
              if (!checkout?.addressId) return { name: 'CheckoutAddress', query: { edit: true } }
              if (!checkout?.shipmethodId)
                return { name: 'CheckoutShipping', query: { edit: true } }
              if ((checkout?.paymentDesc?.length || 0) < 4)
                return { name: 'CheckoutPayment', query: { edit: true } }
              return true
            },
          },
          {
            name: 'CheckoutAddress',
            path: 'address',
            component: () => import('components/checkout/CheckoutAddress.vue'),
            meta: { requireLogin: true, sendToLoginPage: true, requireCart: true },
            beforeEnter: async (to) => {
              if (to.query.edit) return true
              const checkout = useCartStore().cart.checkout
              if (checkout && !checkout.addressId) return true
              return { name: 'Checkout' }
            },
          },
          {
            name: 'CheckoutShipping',
            path: 'shipping',
            component: () => import('components/checkout/CheckoutShipping.vue'),
            meta: { requireLogin: true, sendToLoginPage: true, requireCart: true },
            beforeEnter: async (to) => {
              if (to.query.edit) return true
              const checkout = useCartStore().cart.checkout
              if (checkout && !checkout.shipmethodId) return true
              return { name: 'Checkout' }
            },
          },
          {
            name: 'CheckoutPayment',
            path: 'payment',
            component: () => import('components/checkout/CheckoutPayment.vue'),
            meta: { requireLogin: true, sendToLoginPage: true, requireCart: true },
            beforeEnter: async (to) => {
              if (to.query.edit) return true
              const checkout = useCartStore().cart.checkout
              if (checkout && checkout.paymentDesc && checkout.paymentDesc.length > 3) return true
              return { name: 'Checkout' }
            },
          },
        ],
      },
      {
        name: 'CheckoutComplete',
        path: '/ordercomplete/:orderIdentifier',
        component: () => import('pages/OrderDetailsPage.vue'),
        meta: { checkoutComplete: true, requiresLogin: true },
      },
      {
        name: 'About',
        path: '/about',
        component: () => import('pages/About/AboutHome.vue'),
        children: [
          {
            name: 'OurStory',
            path: 'ourstory',
            component: () => import('pages/About/OurStoryPage.vue'),
          },
          {
            name: 'ContactUs',
            path: 'contact',
            component: () => import('pages/About/ContactUs.vue'),
          },
          {
            name: 'Press',
            path: 'press',
            component: () => import('pages/About/PressPage.vue'),
          },
          {
            name: 'FAQ',
            path: 'faq',
            component: () => import('pages/About/FAQPage.vue'),
          },
          {
            name: 'Shipping',
            path: 'shipping',
            component: () => import('pages/About/ShippingPage.vue'),
          },
          {
            name: 'Stockists',
            path: 'stockists',
            component: () => import('pages/About/StockistsPage.vue'),
          },
          {
            name: 'Collaborations',
            path: 'collaborations',
            component: () => import('pages/About/CollaborationsPage.vue'),
          },
          {
            name: 'Privacy',
            path: 'privacy',
            component: () => import('pages/About/PrivacyPage.vue'),
          },
          {
            name: 'Terms',
            path: 'terms',
            component: () => import('pages/About/TermsPage.vue'),
          },
          {
            name: 'Wholesale',
            path: 'wholesale',
            component: () => import('pages/About/WholesalePage.vue'),
          },
        ],
      },
      {
        name: 'LoginPage',
        path: '/login',
        component: () => import('pages/LoginPage.vue'),
      },
      {
        name: 'Logout',
        path: '/logout',
        redirect: () => {
          useAppServer()
            .logout()
            .catch((e) => console.log(`error logging out : ${e}`))
          return window.location.origin
        },
      },
      {
        path: '/:catchAll(.*)*',
        component: () => import('pages/ErrorPage.vue'),
      },
    ],
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorPage.vue'),
  },
]

export default routes
